<template>
  <div v-if="sitesLoaded" class="pa-5">
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'Sites.WebsitesManagement'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click="$router.push('Sites/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('Sites.AddASite') }}
        </v-btn>
      </v-card-title>
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
          <tr>
            <th class='text-center' v-t="'Sites.SiteName'" ></th>
            <th class='text-center' v-t="'Sites.URL'"></th>
            <th class='text-center' v-t="'Sites.Actions'"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='site in sites' :key='site.id'>
            <td>{{ site.name }}</td>
            <td>{{ site.url }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Sites/' + site.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='archiveSite(site)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Sites',
    data () {
      return {
        testImg: {},
        sitesLoaded: false,
        sites: []
      }
    },
    created () {
      this.getSites()
    },
    methods: {
      archiveSite (site) {
        this.$store.dispatch('archiveSite', site.id).then(() => {
          this.sites = this.$store.getters['GET_SITES']()
          this.$emit('notify', {
            color: 'green',
            text: this.$t('Messages.SiteDeleted')
          })
        }).catch((err) => {
            this.$emit('notify', {
                color: 'red',
                text: this.$t('Messages.' + err.response.data)
            })
        })
      },
      getSites () {
        this.$store.dispatch('getAllSites').then(() => {
          this.sites = this.$store.getters['GET_SITES']()
          this.sitesLoaded = true
        })
      }
    }
  }
</script>

<style scoped>

</style>
